*,
*:after,
*:before {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Prompt';
  src: url('../assets/fonts/Prompt-Regular.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Prompt-Medium';
  src: url('../assets/fonts/Prompt-Medium.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Prompt-Thin';
  src: url('../assets/fonts/Prompt-Thin.ttf'); /* IE9 Compat Modes */
}

@font-face {
  font-family: 'Prompt-Bold';
  src: url('../assets/fonts/Prompt-Bold.ttf'); /* IE9 Compat Modes */
}

html,
body {
  width: 100%;
  height: 100%;
}

html {
  font-size: 16px;
  background-color: #121212;
}

body {
  position: relative;
  z-index: 0;
  min-height: 100%;
  color: rgba(0, 0, 0, 0.87);
  background-color: #121212;
  font-family: 'Prompt', sans-serif;
  font-size: 1.4rem;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  overscroll-behavior: contain;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.display-none {
  display: none;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.flex-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.space-around {
  justify-content: space-around;
}

.space-between {
  justify-content: space-between;
}

.cursor-pointer {
  cursor: pointer;
}

.align-items-center {
  align-items: center;
}
.text-align-center {
  text-align: center;
}

.prevent-text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.red-line {
  position: relative;
  width: 100%;
  height: 1px;
  background-color: #981214;
}

::-webkit-scrollbar-track {
  background-color: #1e1e1e;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: #1e1e1e;
}

::-webkit-scrollbar-thumb {
  background-color: #404040;
  width: 8px;
  height: 100px;
}

input {
  padding: 0;
}

.none-highlight {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* webkit (safari, chrome) browsers */
  -moz-user-select: none; /* mozilla browsers */
  -khtml-user-select: none; /* webkit (konqueror) browsers */
  -ms-user-select: none; /* IE10+ */
}

.toast-container {
  width: 304px;
  padding: 0px;
  .toast {
    padding: 0px;
    .toast-progress {
      visibility: hidden;
    }
  }
}
